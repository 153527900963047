import React, { useEffect, useState } from 'react'
import { CChart } from '@coreui/react-chartjs'
import '../CSS/Charts/DalyuserVisited.css'
import axios from 'axios'

const DalyuserVisited = () => {
    const URL = process.env.REACT_APP_BACKEND_URL
    const [count, setCount] = useState(0)
    useEffect(() => {
        axios.get(`${URL}/admin/data/count/month`, {
            headers: {
                authToken: JSON.parse(localStorage.getItem('adminInfo')).token === undefined ? JSON.parse(localStorage.getItem('adminInfo')) : JSON.parse(localStorage.getItem('adminInfo')).token
            }
        }).then((res) => {
            setCount(res.data)
        }).catch(err => {
            console.log(err)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className='dalyUserVisitCont'>
            <CChart
                type="line"
                data={{
                    labels: ["January", "February", "March", "April", "May", "June", "July","Augest","September","October","November","December"],
                    datasets: [
                        {
                            label: "Data Entry",
                            backgroundColor: "rgba(151, 187, 205, 0.2)",
                            borderColor: "rgba(151, 187, 205, 1)",
                            pointBackgroundColor: "rgba(151, 187, 205, 1)",
                            pointBorderColor: "#fff",
                            data: [count["1"], count["2"], count["3"], count["4"], count["5"], count["6"], count["7"], count["8"], count["9"], count["10"], count["11"], count["12"]],
                            tension: 0.5,
                            fill: true
                        }
                    ],
                }}
            />
        </div>
    )
}

export default DalyuserVisited
