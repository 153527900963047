import React from 'react'
import DashBord from '../admin/DashBord'
import '../CSS/system/Theme.css'

const Theme = () => {
    return (
        <div className='theme'>
            <DashBord />
            <div className="themePanel">
                <div className="header">
                    <p>System Theme</p>
                </div>
                <div className="body">
                    <h3>This page is under Construction🙂</h3>
                </div>
            </div>
        </div>
    )
}

export default Theme
