import React from 'react'
import '../CSS/system/Help.css'
import DashBord from '../admin/DashBord'

const Help = () => {
    return (
        <div className='help'>
            <DashBord />
            <div className="helpPanel">
                <div className="header">
                    <p>Help Desk</p>
                </div>
                <div className="body">
                    <h3>This page is under Construction🙂</h3>
                </div>
            </div>
        </div>
    )
}

export default Help
