import React, { useState, useEffect } from 'react';
import { Modal, useMantineTheme } from "@mantine/core";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DataEdit = ({ openModel, setOpenModel, dataInfo, columnData, setEditData, selectedCategory, setSelectedCategory }) => {
    const URL = process.env.REACT_APP_BACKEND_URL
    const theme = useMantineTheme();
    const [inputValues, setInputValues] = useState({ ...dataInfo, extraData: dataInfo.extraData || [{}] });

    useEffect(() => {
        // Update inputValues whenever dataInfo changes (e.g., when prop dataInfo changes)
        setInputValues({ ...dataInfo, extraData: dataInfo.extraData || [{}] });
        setSelectedCategory(dataInfo.category===undefined?"Advertiser":dataInfo.category);
        // eslint-disable-next-line
    }, [dataInfo]);

    const handleInputChange = (name, value) => {
        setInputValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleExtraDataChange = (data, value) => {
        setInputValues((prev) => ({
            ...prev,
            extraData: prev.extraData.map((item, index) => {
                if (index === 0) {
                    return {
                        ...item,
                        [data]: value,
                    };
                }
                return item;
            }),
        }));
    };

    const handleSaveDetails = (e) => {
        e.preventDefault()
        const adminName = JSON.parse(localStorage.getItem('adminInfo')).adminName === undefined ? "Super Admin" : JSON.parse(localStorage.getItem('adminInfo')).adminName;
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;

        const updatedInputValues = {
            ...inputValues,
            dataEntryOpt: {
                name: adminName,
                date: formattedDate,
            },
            category: selectedCategory
        };

        // Transform extraData to an array with a single object inside
        updatedInputValues.extraData = updatedInputValues.extraData[0];


        axios.post(`${URL}/admin/dataentryopt/dataedit`, updatedInputValues, {
            headers: {
                authToken: localStorage.getItem('adminType') === 'superAdmin' ? JSON.parse(localStorage.getItem('adminInfo')) : JSON.parse(localStorage.getItem('adminInfo')).token
            }
        }).then((response) => {
            toast.success(response.data);
            // window.location.reload();
            setEditData(true)
            setOpenModel(false)
        }).catch(err => {
            toast.error(err.response.data);
        })
    };

    return (
        <div>
            <ToastContainer />
            <Modal
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 4,
                }}
                size="40%"
                opened={openModel}
                onClose={() => setOpenModel(false)}
            >
                <form className='userEditCont'>
                    <h3>Data details</h3>
                    <div className="inputCont">
                        <input type="text" name="name" value={inputValues.name} onChange={(e) => handleInputChange('name', e.target.value)} placeholder='Name' />
                        <input type="email" name="email" value={inputValues.email} onChange={(e) => handleInputChange('email', e.target.value)} placeholder='Email' />
                        <input type="text" name="profession" value={inputValues.profession} onChange={(e) => handleInputChange('profession', e.target.value)} placeholder='Profession' />
                        <input type="number" name="phone" value={inputValues.phone} onChange={(e) => handleInputChange('phone', e.target.value)} placeholder='Phone Number' />
                        <select
                            name="category"
                            id="modalEditCategory"
                            value={selectedCategory}
                            onChange={handleChange}
                        >
                            <option value="Advertiser">Advertiser</option>
                            <option value="Advocate">Advocate</option>
                            <option value="BJP">BJP</option>
                            <option value="Businessman">Businessman</option>
                            <option value="Congress">Congress</option>
                            <option value="Contact in Other States">Contact in Other States</option>
                            <option value="Contact of Kalom">Contact of Kalom</option>
                            <option value="Councillor">Councillor</option>
                            <option value="CPIM">CPIM</option>
                            <option value="District Corresponds">District Corresponds</option>
                            <option value="Doctor">Doctor</option>
                            <option value="Froeign Writer">Froeign Writer</option>
                            <option value="Kalom Employees">Kalom Employees</option>
                            <option value="Minister">Minister</option>
                            <option value="MLA">MLA</option>
                            <option value="MPs">MPs</option>
                            <option value="Politician">Politician</option>
                            <option value="Professor">Professor</option>
                            <option value="TMC">TMC</option>
                            <option value="Writer">Writer</option>
                            <option value="Writer Bangladesh">Writer Bangladesh</option>
                            <option value="Islamic Scholars">Islamic Scholars</option>
                            <option value="Other">Other</option>
                        </select>
                        {columnData && dataInfo &&
                            columnData.addColumn.map((data) => {
                                const value = inputValues.extraData[0][data] || "";
                                return (
                                    <input
                                        type="text"
                                        name={data}
                                        placeholder={data}
                                        value={value}
                                        onChange={(e) => {
                                            handleExtraDataChange(data, e.target.value);
                                        }}
                                        key={data}
                                    />
                                )
                            })}
                    </div>
                    <div className="btnCont">
                        <button className="saveBtn" onClick={handleSaveDetails}>Save Details</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default DataEdit;
